body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-position: inside !important;
}

li::marker {
  content: "•"; /* Use a different character or content */
  font-size: 1rem; /* Customize the size */
  font-weight: bold; /* Customize the weight */
}

/* Global custom scroll bar styles for WebKit browsers */
::-webkit-scrollbar {
  width: 3px; /* Width of the scroll bar */
}

/* Scroll bar handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll bar handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

/* Scroll bar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Scroll bar track (optional) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}
